import React from "react"
const Privacy = () => {
  return (
    <>
      <div className="docs">
        <p>
          <strong>Privacy Statement of Shots Fired BV</strong>
        </p>
        <p>
          Your privacy is important to us. It is Shots Fired BV&#39;s policy to
          respect your privacy regarding any information we may collect from you
          across our website, newdancetv.com, and other sites and the New Dance
          TV application that we own and operate.
        </p>
        <p>
          During the processing of personal data New Dance BV works conform the
          requirements of the applicable data protection legislation, like the
          General Data Protection Regulation. This means we:
        </p>
        <ul>
          <li>
            <strong>clearly specify our purposes</strong> before we process
            personal data, by using this privacy statement;
          </li>
          <li>
            <strong>limit our collection of personal data</strong> to only the
            personal data needed for legitimate purposes;
          </li>
          <li>
            first <strong>ask for explicit permission</strong> to process your
            personal data in cases where your permission is required;
          </li>
          <li>
            take <strong>appropriate security measures</strong> to protect your
            personal data and we demand the same from parties who process
            personal data on our behalf;
          </li>
          <li>
            <strong>respect</strong> your right to access, correct or delete
            your personal data held by us.
          </li>
        </ul>
        <p>
          New Dance BV is the party responsible for all data processing. In this
          privacy statement, we will explain what kind of personal data we
          collect and for which purposes within our products. We recommend that
          you read it carefully.
        </p>
        <p>
          If you have any questions regarding the processing of personal data,
          you can find the contact details of Shots Fired BV, the legal entity
          that operates New Dance TV at the end of this privacy statement.
        </p>
        <p>
          <strong>1. Registration</strong> \ Certain features of our service
          require you to register beforehand. You will have to provide some
          information about yourself and choose a username and password for the
          account that we will set up for you.
        </p>
        <p>
          For this purpose, we use your email address and name. We do this on
          the basis of your consent. We store this information until you close
          your account.
        </p>
        <p>
          We will retain this data so that you do not have to re-enter it every
          time you visit our website, and in order to contact you in connection
          with the execution of the agreement, invoicing and payment, and to
          provide an overview of the products and services you have purchased
          from us.
        </p>
        <p>
          <strong>2. Logging statistics and profiling</strong> \ We keep
          statistics on the use of our website and mobile app, but these are
          kept anonymous at all times. These statistics help us to, for example,
          only show you information that is relevant to you. We may combine
          personal data to get to know more about you. We will of course respect
          your privacy at all times.{" "}
        </p>
        <p>
          For this purpose, we use your Firstname Lastname and email address. We
          do this on the basis of your consent. We store this information for
          six months.
        </p>
        <p>
          <strong>
            3. Disclosure of personal information to third parties
          </strong>
        </p>
        <p>We may disclose personal information to:</p>
        <ul>
          <li>
            third party service providers for the purpose of enabling them to
            provide their services, including (without limitation) IT service
            providers, data storage, hosting and server providers, ad networks,
            analytics, error loggers, debt collectors, maintenance or
            problem-solving providers, marketing or advertising providers,
            professional advisors and payment systems operators;
          </li>
          <li>sponsors or promoters of any competition we run;</li>
          <li>
            courts, tribunals, regulatory authorities and law enforcement
            officers, as required by law, in connection with any actual or
            prospective legal proceedings, or in order to establish, exercise or
            defend our legal rights; and
          </li>
          <li>
            third parties, including agents or sub-contractors, who assist us in
            providing information, products, services or direct marketing to
            you.
          </li>
        </ul>
        <p>
          <strong>4. International transfers of personal information</strong>
        </p>
        <p>
          The personal information we collect is stored and processed in Ireland
          and Netherlands, or where we or our partners, affiliates and
          third-party providers maintain facilities. By providing us with your
          personal information, you consent to the disclosure to these overseas
          third parties.
        </p>
        <p>
          We will ensure that any transfer of personal information from
          countries in the European Economic Area (EEA) to countries outside the
          EEA will be protected by appropriate safeguards, for example by using
          standard data protection clauses approved by the European Commission,
          or the use of binding corporate rules or other legally accepted means.
        </p>
        <p>
          Where we transfer personal information from a non-EEA country to
          another country, you acknowledge that third parties in other
          jurisdictions may not be subject to similar data protection laws to
          the ones in our jurisdiction. There are risks if any such third party
          engages in any act or practice that would contravene the data privacy
          laws in our jurisdiction and this might mean that you will not be able
          to seek redress under our jurisdiction’s privacy laws.
        </p>
        <p>
          <strong>
            5. Your rights and controlling your personal information
          </strong>
        </p>
        <p>
          Choice and consent: By providing personal information to us, you
          consent to us collecting, holding, using and disclosing your personal
          information in accordance with this privacy policy. If you are under
          16 years of age, you must have, and warrant to the extent permitted by
          law to us, that you have your parent or legal guardian’s permission to
          access and use the website and they (your parents or guardian) have
          consented to you providing us with your personal information. You do
          not have to provide personal information to us, however, if you do
          not, it may affect your use of this website or the products and/or
          services offered on or through it.
        </p>
        <p>
          Information from third parties: If we receive personal information
          about you from a third party, we will protect it as set out in this
          privacy policy. If you are a third party providing personal
          information about somebody else, you represent and warrant that you
          have such person’s consent to provide the personal information to us.
        </p>
        <p>
          Restrict: You may choose to restrict the collection or use of your
          personal information. If you have previously agreed to us using your
          personal information for direct marketing purposes, you may change
          your mind at any time by contacting us using the details below. If you
          ask us to restrict or limit how we process your personal information,
          we will let you know how the restriction affects your use of our
          website or products and services.
        </p>
        <p>
          Access and data portability: You may request details of the personal
          information that we hold about you. You may request a copy of the
          personal information we hold about you. Where possible, we will
          provide this information in CSV format or other easily readable
          machine format. You may request that we erase the personal information
          we hold about you at any time. You may also request that we transfer
          this personal information to another third party.
        </p>
        <p>
          Correction: If you believe that any information we hold about you is
          inaccurate, out of date, incomplete, irrelevant or misleading, please
          contact us using the details below. We will take reasonable steps to
          correct any information found to be inaccurate, incomplete, misleading
          or out of date.
        </p>
        <p>
          Notification of data breaches: We will comply with laws applicable to
          us in respect of any data breach.
        </p>
        <p>
          Complaints: If you believe that we have breached a relevant data
          protection law and wish to make a complaint, please contact us using
          the details below and provide us with full details of the alleged
          breach. We will promptly investigate your complaint and respond to
          you, in writing, setting out the outcome of our investigation and the
          steps we will take to deal with your complaint. You also have the
          right to contact a regulatory body or data protection authority in
          relation to your complaint.
        </p>
        <p>
          Unsubscribe: To unsubscribe from our e-mail database or opt-out of
          communications (including marketing communications), please contact us
          using the details below or opt-out using the opt-out facilities
          provided in the communication.
        </p>
        <p>
          <strong>6. Sending newsletters</strong> \ We have a newsletter to
          inform those interested on news, tips and information about our
          products and services. Your e-mail address is automatically added to
          the list of subscribers.
        </p>
        <p>
          For this purpose, we use your name and email address. We do this on
          the basis of your consent. We store this information until you cancel
          your subscription.
        </p>
        <p>
          You may cancel your subscription to our newsletter at any time. Each
          newsletter contains a link to unsubscribe from our newsletter.
        </p>
        <p>
          <strong>7. Access to portal</strong> \ Within our app, you can access
          a management environment (studio) where you can upload, specify and
          change video content. We will keep track of your activities for proof,
          and to further improve our user experience.
        </p>
        <p>
          For this purpose, we use your email address and name. We do this on
          the basis of your consent. We store this information until our
          services to you have ended.
        </p>
        <p>
          <strong>8. Inspection and modification of your data</strong>You can
          always contact us if you have any questions regarding our privacy
          policy or wish to review, modify or delete your personal data.
        </p>
        <p>You have the following rights:</p>
        <pre>
          <code>
            Right <span class="hljs-keyword">of</span> access: you have{" "}
            <span class="hljs-keyword">the</span> right{" "}
            <span class="hljs-keyword">to</span> see what kind{" "}
            <span class="hljs-keyword">of</span> personal data we processed{" "}
            <span class="hljs-keyword">about</span> you; Right{" "}
            <span class="hljs-keyword">of</span> rectification: you have{" "}
            <span class="hljs-keyword">the</span> right{" "}
            <span class="hljs-keyword">to</span> rectify any personal data we
            have processed <span class="hljs-keyword">about</span> you,{" "}
            <span class="hljs-keyword">if</span> this information{" "}
            <span class="hljs-keyword">is</span> (partially) wrong; Right{" "}
            <span class="hljs-keyword">to</span> complain: you have{" "}
            <span class="hljs-keyword">the</span> right{" "}
            <span class="hljs-keyword">to</span>{" "}
            <span class="hljs-built_in">file</span> a complaint{" "}
            <span class="hljs-keyword">against</span>{" "}
            <span class="hljs-keyword">the</span> processing{" "}
            <span class="hljs-keyword">of</span> your personal data{" "}
            <span class="hljs-keyword">by</span> us,{" "}
            <span class="hljs-keyword">or</span>{" "}
            <span class="hljs-keyword">against</span> direct marketing; Right{" "}
            <span class="hljs-keyword">to</span> be forgotten: you can{" "}
            <span class="hljs-built_in">file</span> a request{" "}
            <span class="hljs-keyword">with</span> us{" "}
            <span class="hljs-keyword">to</span> remove any personal data we
            have processed <span class="hljs-keyword">of</span> you; Right{" "}
            <span class="hljs-keyword">to</span> data portability:{" "}
            <span class="hljs-keyword">if</span> technically possible, you have{" "}
            <span class="hljs-keyword">the</span> right{" "}
            <span class="hljs-keyword">to</span> ask us{" "}
            <span class="hljs-keyword">to</span> transfer your processed
            personal data <span class="hljs-keyword">to</span> a{" "}
            <span class="hljs-keyword">third</span> party; Right{" "}
            <span class="hljs-keyword">to</span> restriction{" "}
            <span class="hljs-keyword">of</span> processing: you can{" "}
            <span class="hljs-built_in">file</span> a request{" "}
            <span class="hljs-keyword">with</span> us{" "}
            <span class="hljs-keyword">to</span> (temporarily) restrict{" "}
            <span class="hljs-keyword">the</span> processing{" "}
            <span class="hljs-keyword">of</span> your personal data.
          </code>
        </pre>
        <p>
          If you exercise any of the rights mentioned above, we might ask to
          identify yourself with a valid ID, to confirm it is your personal
          data. If so, it is important that you hide your social security number
          and photo.
        </p>
        <p>
          We will usually respond to your request within 30 days. This term can
          be extended if the request is proven to be complex or tied to a
          specific right. You will be notified about a possible extension of
          this term.
        </p>
        <p>
          <strong>8. Cookies</strong> \ Our website makes use of cookies.
          Cookies are small files in which we can store information, so that you
          do not have to fill in that information again. We can also use them to
          see whether you are visiting us again.
        </p>
        <p>
          The first time you visit our , we will show you a notification
          explaining our cookies and ask for your permission for the use of
          these cookies.
        </p>
        <p>
          You can disable the use of cookies through your browser setting, but
          some parts of our website may not work properly as a result of that.
        </p>
        <p>
          <strong>9. Security</strong> \ We take security measures to reduce
          misuse of and unauthorized access to personal data. We take
          responsibility in the security of your personal data. We renew our
          security measures to ensure safe storage of personal data and keep
          track what might go wrong.
        </p>
        <p>
          <strong>10. Business transfers</strong>If we or our assets are
          acquired, or in the unlikely event that we go out of business or enter
          bankruptcy, we would include data among the assets transferred to any
          parties who acquire us. You acknowledge that such transfers may occur,
          and that any parties who acquire us may continue to use your personal
          information according to this policy.
        </p>
        <p>
          <strong>11. Changes to this privacy statement</strong> \ We reserve
          the right to modify this statement. We recommend that you consult this
          statement on a regular basis, so that you remain informed of any
          changes.
        </p>
        <p>
          <strong>Complaints</strong> \ If you want to file a complaint about
          our use of personal data, please send an email with the details of
          your complaint to info@newdancetv.com. We will look into and respond
          to any complaint we receive.
        </p>
        <p>
          If you think that we are not helping you in the right way, you have
          the right to file a complaint at the authority. For The Netherlands,
          this is the Autoriteit Persoonsgegevens.
        </p>
        <p>
          <strong>Contact details</strong> \ Shots Fired BV \ Kloosterweg 1 \
          6412CN Heerlen \ info@newdancetv.com
        </p>
        <p>Policy is effective as of 6 februari 2022</p>
      </div>
    </>
  )
}

export default Privacy
